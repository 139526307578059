<template>
    <div class="loginBG">
        <div class="contentbox">
            <div class="contItem">
                <div class="leftBox">
                    <!-- <div class="topTime">
                        <div class="times">{{isDownNum}}</div>
                        <div class="timeInfo">下一个倒计时</div>
                    </div> -->
                    <div class="numberC">
                        <span>{{ currentWordIndex+1 }}</span>/<span>{{ wordList.length }}</span>
                    </div>
                    <div class="wordBox" v-if="isOver" :style="{fontSize:value3+'px'}"><span>默写结束</span></div>
                    <!-- <div class="wordBox" v-if="showType == 2 && !isOver" :style="{fontSize:value3+'px'}"> <span v-if="wordInfo.DictationPrint==1 || wordInfo.DictationPrint==13">{{newWord.WorkPinYin}}</span><span v-if="wordInfo.DictationPrint==2 || wordInfo.DictationPrint==23">{{newWord.WordName}}</span></div> -->
                    <!-- WordName -->
                    <div class="wordBox" v-if="showType == 2 && !isOver" :style="{fontSize:value3+'px'}">
                        
                          <div class="pinLIST" v-if="showType1==2">
                            <div v-for="item in newWord.wordCi"> {{item}}</div>
                            </div>
                        <div v-if="showType1==2">
                            <div class="handBg" :style="{fontSize:value3+'px'}">
                            <div v-for="item in newWord.WordName"> {{item}}</div>
                        </div>
                        </div>
                        <div v-if="showType1==1">
                              <div class="handBg1" :style="{fontSize:value3+'px'}">
                                <div v-for="item in newWord.WordName" :style="{width:value3+'px',height:value3+'px'}"> {{item}}</div>
                            </div>
                        </div>
                         <!-- <div class="handBg" :style="{fontSize:value3+'px'}">
                            <div v-for="item in newWord.WordName"> {{item}}</div>
                         </div> -->
                    </div>
                    <div class="wordBox1" v-if="showType == 1 && !isOver">
                        <div class="worditem" v-for="(item,index) in wordList" :key="item.WordID">
                            <div v-if="showType1==2">
                                <div class="pinBox" v-for="item1 in item.itemPin" :style="{color:currentWordIndex == index?'red':''}"><span>{{item1}}</span></div>
                            </div>
                            <div :style="{fontSize:value3+'px'}" v-if="showType1==2">
                                <div class="ziBox" v-for="item1 in item.typeface"  :style="{color:currentWordIndex == index?'red':''}"><span >{{item1}}</span></div>
                            </div>
                             <div :style="{fontSize:value3+'px'}" v-if="showType1==1">
                                <div class="ziBox1" v-for="item1 in item.typeface"  :style="{color:currentWordIndex == index?'red':'',width:value3+'px',height:value3+'px'}"><span >{{item1}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="rightBox">
                <!-- <div class="timesNumber"><Icon type="ios-clock" size="30" color="#207DE5" />用时</div>
                <div class="numberItem">
                    <div class="timeItem">{{minutes1}}</div>
                    <div class="timeItem">{{minutes2}}</div>
                    <div class="timeItem1">:</div>
                    <div class="timeItem">{{seconds1}}</div>
                    <div class="timeItem">{{seconds2}}</div>
                </div> -->
                <Dropdown @on-click="checkType1">
                    <Button type="primary" class="goback"><Icon type="ios-arrow-down"></Icon><span v-if="showType1 ==1">仅文字</span><span v-if="showType1 == 2">含拼音</span></Button>
                    <DropdownMenu slot="list">
                        <DropdownItem :name='1'>仅文字</DropdownItem>
                        <DropdownItem :name='2'>含拼音</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <Dropdown @on-click="checkType">
                    <Button type="primary" class="goback"><Icon type="ios-arrow-down"></Icon><span v-if="showType ==1">显示整体</span><span v-if="showType == 2">显示单个</span></Button>
                    <DropdownMenu slot="list">
                        <DropdownItem :name='1'>显示整体</DropdownItem>
                        <DropdownItem :name='2'>显示单个</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <div class="startBtn" @click="proSubject"><Icon type="md-arrow-dropup" style="margin-top:-4px" size="30" />上一题</div>
                <div class="startBtn" @click="nextSubJect" style="margin-top:30px"><Icon type="md-arrow-dropdown" size="30" />下一题</div>
                <Button type="primary" class="goback" @click="goback"><Icon v-if="!isPay" type="md-arrow-dropright-circle" /><Icon v-if="isPay" type="md-pause" /> <span v-if="isPay">暂停朗读</span><span v-if="!isPay">自动朗读</span> </Button>
                 <Button type="primary" style="z-index:9999999" class="goback" @click="goHome"><Icon type="md-exit" />返回列表</Button>
            </div>
            </div>
            <audio ref="audio"  controls="controls" class="audiosBox" @ended="overAudio"></audio>
            <div class="bottomBox">
                <div>
                    <span>读</span>
                    <Input-number v-model="value1" :min="1" :max="10" size="large"></Input-number>
                    <span>遍</span>
                </div>
                <div style="width:360px">
                    <span>每隔</span>
                    <Input-number v-model="value2" :min="2" :max="20" size="large"></Input-number>
                    <span>秒读下一个</span>
                </div>
                 <div>
                    <span>字号</span>
                    <Input-number v-model="value3" @on-change="chengSize" :min="showType ==1? 80:120" :max="showType ==1? 100:170" size="large"></Input-number>
                </div>
                
            </div>
        </div>
        <div class="payVipBox" v-if="!unlimited">
            <div class="PayBoxs">
                <div class="payTitle">提示</div>
                <div class="infosBox">开通会员后即可使用小程序全部功能(包含投屏默写)</div>
                <div class="payMage">
                    <img src="../assets/pay.png"></img>
                </div>
                <div class="buttonTitle">扫码立即开通永久会员</div>
            </div>
            
            <Button type="primary" style="z-index:9999999" class="goback  posin" @click="goHome"><Icon type="md-exit" />返回列表</Button>
        </div>
    </div>

</template>
  
  <script>
import axios from "axios";
import qs from "qs"
export default {
    data() {
      return {
        value1:2,//多少遍
        value2:3,//间隔
        value3:120,//字体大小
        currentWordIndex: 0, // 当前第n个单词
        wordInfo: {},//请求数据
        wordList: [], // 词语列表
        newWord:'',//当前词语
        SimpleWords:'',//单词列表
        minutes: 0,  
        seconds: 0,
        minutes1: 0,  
        seconds1: 0,
        minutes2: 0,  
        seconds2: 0,
        timer:'',//计时器
        ticket:'',//下载凭证
        wordUrl:'',//下载url
        isNum:'',//第几次
        jsTime:'',//倒计时定时器
        isPay:false,//是否播放
        isDownNum:'',//倒计时
        showType:2,//显示类型
        isMiao:'',
        isOver:false,
        daoTime:'',
        unlimited:true,
        showType1:1
      };
    },
    created() {
    },
    mounted() {
        this.id = this.$route.query.id
        // this.showType = this.$route.query.showType
        this.queryWordList()
        this.isDownNum= this.value2 
        this.isQueryVip()
    },
    methods: {
        chengSize(e){
            console.log(e)
             if(this.showType == 1){
                if(Number(e)>100){
                    this.value3 = 100
                }else if(Number(e)<80){
                    this.value3 = 80
                }else{
                     this.value3 = Number(e)
                }
            }else{
                if(Number(e)>170){
                    this.value3 = 170
                }else if(Number(e)<120){
                    this.value3 = 120
                }else{
                     this.value3 = Number(e)
                }
            }
        },
        isQueryVip(){
        var that = this
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
                'Authorization': window.localStorage.getItem('sessionId'),
            },
            method: 'GET',
            url: '/v1/user/perm',
            })
            .then((res) => {
                    that.unlimited = res.data.data.unlimited
                })
            .catch((err) => {
                that.$Message.error(err);
            });
        },
        checkType(e){
            this.showType = e
            
            if(this.showType == 1){
                this.value3=80
            }else{
                this.value3=120
            }
            console.log(this.wordList)
        },
         checkType1(e){
            this.showType1 = e
        },
        
        goHome(){
            this.$router.push({name:'topicList'})
        },
        startTimer () {
            this.seconds += 1; //秒自增 >=60变成 0秒 分加 1分钟
            if (this.seconds >= 60) {
                this.seconds = 0;
                this.minutes = this.minutes + 1;
            }
            let s = this.seconds
            let m = this.minutes
            if(s>9){
              this.seconds1 = s.toString().substring(0,1)
              this.seconds2 = s.toString().substring(2,1)
            }else{
              this.seconds1 =  '0'
              this.seconds2 = s.toString().substring(0,1)
            }
            if(m>9){
              this.minutes1 = m.toString().substring(0,1)
              this.minutes2 = m.toString().substring(2,1)
            }else{
                this.minutes1 =  '0'
                this.minutes2 = m.toString().substring(0,1)
            }
        },
        goback(){
            this.isPay = !this.isPay
            // window.localStorage.setItem('pc_severalTimes',this.value1)
            // window.localStorage.setItem('pc_interval',this.value2)
            if(this.isPay){
                this.$refs.audio.pause()
                clearTimeout(this.jsTime)
                clearInterval(this.timer)
                clearInterval(this.setint)
                clearTimeout(this.isMiao)
                clearTimeout(this.daoTime)
                
                this.timer = setInterval(this.startTimer, 1000);
                this.isOver = false
                if(this.currentWordIndex == 0){
                    this.newWord = this.SimpleWords[this.currentWordIndex]
                    this.seconds = 0
                    this.seconds1 = 0
                    this.seconds2 = 0
                    this.minutes1 = 0
                    this.minutes2 = 0
                }
                if(this.newWord.WordName.length==1){
                this.$refs.audio.src = encodeURI(this.wordUrl+'&word='+this.newWord.WordName)+'&pinyin='+this.newWord.WorkPinYin
                }else{
                this.$refs.audio.src = encodeURI(this.wordUrl+'&word='+this.newWord.WordName)
                }
                this.$refs.audio.play()

            }else{
                // this.currentWordIndex = 0
                this.newWord = this.SimpleWords[this.currentWordIndex]
                clearTimeout(this.jsTime)
                clearInterval(this.timer)
                clearInterval(this.setint)
                clearTimeout(this.daoTime)
                clearTimeout(this.isMiao)
                this.isDownNum = this.value2
                this.$refs.audio.pause()
                
            }
        },
      proSubject() {
        if(this.currentWordIndex == 0){
            this.$Message.warning('当前已经是第一题');
        }else{
            this.$refs.audio.pause()
            this.currentWordIndex = this.currentWordIndex -1
            this.newWord = this.SimpleWords[this.currentWordIndex]
            this.isNum = 1
            clearTimeout(this.jsTime)
            clearInterval(this.timer)
            clearInterval(this.setint)
            clearTimeout(this.isMiao)
            clearTimeout(this.daoTime)
            this.isPay = false
        }
      },
      nextSubJect(){
        if(this.currentWordIndex == this.wordList.length-1){
            this.$Message.warning('当前已经是最后一题');
        }else{
            this.$refs.audio.pause()
            this.currentWordIndex = this.currentWordIndex +1
            this.newWord = this.SimpleWords[this.currentWordIndex]
            this.newWord.wordCi = this.newWord.WorkPinYin.split(' ')
            this.isNum = 1
            clearTimeout(this.jsTime)
            clearInterval(this.timer)
            clearInterval(this.setint)
            clearTimeout(this.isMiao)
            clearTimeout(this.daoTime)
            this.isPay = false
        }
      },
        overAudio(){
            const that = this
            console.log(that.isNum,that.value1)
            if(that.isNum == that.value1){
                that.isNum = 1
                let num2 = that.value2
                if(that.currentWordIndex+1 == that.SimpleWords.length){
                    that.isDownNum = that.value2
                    clearTimeout(this.jsTime)
                    clearInterval(this.timer)
                    clearInterval(this.setint)
                    clearTimeout(this.isMiao)
                    that.currentWordIndex = 0
                    that.daoTime = setTimeout(() => {
                         that.isOver = true
                    }, that.value2*1000);
                    this.isPay = false
                }else{
                    that.setint = setInterval(function(){
                    num2 = num2 -1
                    if(num2 == 0){
                        clearInterval(that.setint)
                        that.isDownNum = that.value2
                    }else{
                         that.isDownNum = num2
                    }
                },1000)
                    that.jsTime = setTimeout(function(){
                    that.currentWordIndex = that.currentWordIndex+1
                    that.newWord = that.SimpleWords[that.currentWordIndex]
                    that.newWord.wordCi = that.newWord.WorkPinYin.split(' ')
                    if(that.newWord.WordName.length==1){
                        that.$refs.audio.src = encodeURI(that.wordUrl+'&word='+that.newWord.WordName)+'&pinyin='+that.newWord.WorkPinYin
                    }else{
                        that.$refs.audio.src = encodeURI(that.wordUrl+'&word='+that.newWord.WordName)
                    }
                    that.$refs.audio.play()
                },that.value2*1000)
                }
            }else{
                const that =this
                this.isMiao = setTimeout(() => {
                     if(that.newWord.WordName.length==1){
                        that.$refs.audio.src = encodeURI(that.wordUrl+'&word='+that.newWord.WordName)+'&pinyin='+that.newWord.WorkPinYin
                        }else{
                        that.$refs.audio.src = encodeURI(that.wordUrl+'&word='+that.newWord.WordName)

                        }
                    that.$refs.audio.play()
                    that.isNum = this.isNum+1
                }, 1000);
            }
            
        },
      queryVideoUrl(){
        const that = this
        axios({
          headers:{
            'Content-Type':'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('sessionId'),
          },
          method: 'GET',
           url: '/v1/ticket',
          data:qs.stringify({
                id: window.localStorage.getItem('sessionId'),
                DictationID:that.id,
                PrintAns: 2,
                From: 1
            })  
          })
        .then((res) => {
            if(res.data.code == 0){
               that.wordUrl = 'https://cdn.bjwxjs.com/we/file'+'?ticket='+res.data.data.ticket
                if(that.newWord.WordName.length==1){
                that.$refs.audio.src = encodeURI(that.wordUrl+'&word='+that.newWord.WordName)+'&pinyin='+that.newWord.WorkPinYin
                }else{
                that.$refs.audio.src = encodeURI(that.wordUrl+'&word='+that.newWord.WordName)
                }
                that.isNum = 1
            }else{
                if(res.data.msg == '无效的sessionid'){
                    that.$router.push({name:'login'});
                }else{
                    that.$Message.error(res.data.msg);
                }
            }
            })
        .catch((err) => {
            that.$Message.error(err);
        });
      },
      queryWordList(){
        const that = this
        axios({
          headers:{
            'Content-Type':'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('sessionId')
          },
          method: 'POST',
          url: '/Home/ReadDictation',
          data:qs.stringify({
                id: window.localStorage.getItem('sessionId'),
                DictationID: that.id,
                
            })  
          })
        .then((res) => {
            if(res.data.success){
                that.spinShow = false
                that.wordInfo = res.data.data
                var listData = []
                let SimpleWords = []
                if(res.data.data.MultiBlock){
                   let checkboxItems = res.data.data.MultiBlockWords
                    checkboxItems.map(item=>{
                        SimpleWords = SimpleWords.concat(item.Words)
                    })
                    that.SimpleWords = SimpleWords
                     for(var i = 0;i<SimpleWords.length;i++){
                    let data = {
                        itemPin:[],
                        typeface:[]
                    }
                    data.itemPin = SimpleWords[i].WorkPinYin.split(' ')
                    data.typeface = SimpleWords[i].WordName.split('')
                    listData.push(data)
                    }
                }else{
                   SimpleWords = res.data.data.SimpleWords || []
                    that.SimpleWords = res.data.data.SimpleWords
                    for(var i = 0;i<SimpleWords.length;i++){
                        let data = {
                            itemPin:[],
                            typeface:[]
                        }
                        data.itemPin = SimpleWords[i].WorkPinYin.split(' ')
                        data.typeface = SimpleWords[i].WordName.split('')
                        listData.push(data)
                    }
                    }
                    that.wordList = listData
                    that.newWord = SimpleWords[that.currentWordIndex]
                    that.newWord.wordCi = that.newWord.WorkPinYin.split(' ')
                    that.queryVideoUrl()
                    console.log(that.wordList)
                    console.log(that.newWord)

            }else{
                if(res.data.msg == '无效的sessionid'){
                    that.$router.push({name:'login'});
                }else{
                    that.$Message.error(res.data.msg);
                }
            }
            })
        .catch((err) => {
            that.$Message.error(err);
        });
      }
    },

  };
  </script>
  
  <style scoped>
    .loginBG{
        width: 100%;
        height: 100%;
        background: url('https://r.bjwxjs.com/PC_kpy/pc_topic_bg.png') 0 0 / 100% 100% no-repeat;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .contentbox{
        padding:50px;
        box-sizing: border-box;
        height: 100%;
        margin:0 auto;
        width: 100%;
    }
    .contItem{
        width: 100%;
        height: 90%;
        display: flex;
        
    }
    .leftBox{
        width: 85%;
        height: 95%;
        /* background: url('https://r.bjwxjs.com/PC_kpy/pc_answerpage.png') 0 0/ 100% 100% no-repeat; */
        /* margin-top: 60px; */
        position: relative;
    }
    .topTime{
        width: 280px;
        height: 150px;
        background: #207DE5;
        border-radius: 75px;
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .times{
        font-size: 90px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 100px;
        text-align: center;
        
    }
    .ivu-dropdown-menu{
        width: 160px;
        font-size: 22px;
    }
    .ivu-dropdown-item{
        height: 40px;
        font-size: 24px !important;
        text-align: center;
    }
    .timeInfo{
        font-size: 24px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
    }
   .numberC{
        font-size: 26px;
        color: #606266;
        padding: 40px 60px 20px;
        box-sizing: border-box;
        
   }
   .wordBox{
        width: 100%;
        height: 70%;;
        text-align: center;
        position: relative;
   }
   .wordBox span{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        display: inline-block;
        font-family: 'pinyin';

   }
   .wordBox1{
        width: 100%;
        height: 85%;
        overflow: auto;
        display: flex;
        flex-wrap:wrap;
        padding:0 50px 0 100px;

   }
   
@media (max-width: 1440px) {
      .wordBox1{
        width: 100%;
        height: 80%;
        overflow: auto;
        display: flex;
        flex-wrap:wrap;
        padding:0 50px 0 100px;

   }
} 

   .bottomBox{
        width: 800px;
        height: 60px;
        background: #FCFDFF;
        box-shadow: 0px 2px 5px 0px #95C9FF;
        border-radius: 15px;
        margin-left: 10px;
        display: flex;
        font-size: 24px;
        font-weight: 500;
        color: #606266;
        text-shadow: 0px 2px 10px #95C9FF;
        line-height: 60px;
        margin:20px auto;
   }
   .bottomBox > div{
        width: 320px;
        text-align: center;
   }
   .ivu-input-number-large{
        font-size: 20px;
        margin: 0 15px;
        text-align: center;
   }
  /deep/ .ivu-input-number-input{
        text-align: center;
   }
   .timesNumber{
        font-size: 26px;
        font-weight: 500;
        color: #303133;
        text-align: center;
        margin-top: 80px;
        box-sizing: border-box;
   }
   .numberItem{
        display: flex;
        width: 100%; 
        height: 75px;
        margin-top: 30px;
        padding-left: 20px;
        box-sizing: border-box;
   }
   .timeItem{
        width: 36px;
        height: 60px;
        background: rgba(255,255,255,0.8);
        box-shadow: 0px 2px 5px 0px #95C9FF;
        border-radius: 6px;
        border: 2px solid #207DE5;
        font-size: 36px;
        font-weight: normal;
        color: #303133;
        line-height: 60px;
        margin-right: 10px;
        text-align: center;
   }
   .timeItem1{
        font-size: 36px;
        font-weight: normal;
        color: #303133;
        line-height: 60px;
         margin-right: 10px;
   }
   .startBtn{
        width: 180px;
        height: 70px;
        background: url('https://r.bjwxjs.com/PC_kpy/pc_button_BG@2x.png') 0 0 / 100% 100% no-repeat;
        font-size: 26px;
        font-weight: normal;
        color: #207DE5;
        text-align: center;
        line-height: 70px;
        margin-top: 30px;
        margin-left: 10px;
        cursor:pointer
    }
    .goback{
        width: 160px;
        height: 50px;
        margin-top: 30px;
        font-size: 20px;
        margin-left:20px
    }
    .rightBox{
        width: 15%;
    }
    .audiosBox{
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0;
    }
    .worditem{
       margin-right: 60px;
    }
    .worditem >div{
         display: flex;
        flex-wrap: wrap;
    }
     ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .pinBox{
        font-size: 26px;
        font-family: "pinyin";;
        color: #000000;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
        background: url('https://r.bjwxjs.com/PC_kpy/IMG_9180.png') center center / 100% 60% no-repeat;
    }
    .pinBox:nth-child(2){
        margin-left: -1px;
    }
    .pinBox:nth-child(3){
        margin-left: -1px;
    }
    .pinBox:nth-child(4){
        margin-left: -1px;
    }
    .ziBox{
        
        color: #000000;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
        background: url('https://r.bjwxjs.com/PC_kpy/pc_fangge_icon.png') 0 0 / 100% 100% no-repeat;
    }
    .ziBox:nth-child(2){
        margin-left: -1px;
    }
    .ziBox:nth-child(3){
        margin-left: -1px;
    }
    .ziBox:nth-child(4){
        margin-left: -1px;
    }

    .ziBox1{
        
        color: #000000;
        width: 80px;
        height: 80px;
        line-height: 100px;
        text-align: center;
    }
    .ziBox1:nth-child(2){
        margin-left: -1px;
    }
    .ziBox1:nth-child(3){
        margin-left: -1px;
    }
    .ziBox1:nth-child(4){
        margin-left: -1px;
    }
    .payVipBox{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background:rgba(0, 0, 0, 0.4);
        z-index: 10;
    }
    .PayBoxs{
        width: 500px;
        height: 500px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #FFFFFF;
        border-radius: 20px;
    }
    .payTitle{
        font-size: 32px;
        font-weight: 600;
        text-align: center;
        margin-top: 30px;
        color: #333333;
    }
    .infosBox{
        font-size: 24px;
        color: #333333;
        text-indent:2em;
        line-height: 40px;
        padding: 0 30px;
        box-sizing: border-box;
        margin-top: 20px;
    }
    .payMage{
        width: 200px;
        height: 200px;
        margin:20px auto;
    }
    .payMage img{
        width: 100%;
        height: 100%;
    }
    .buttonTitle{
        text-align: center;
        color: #333333;
        font-size: 26px;
    }
    .posin{
        position: absolute;
        bottom: 70px;
        right: 80px;
    }
    .handBg, .handBg1{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-size: 100px;
    }
    .pinLIST{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
         font-size: 60px;
    }
    .handBg div{
       
        color: #000000;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
        background: url('https://r.bjwxjs.com/PC_kpy/pc_fangge_icon.png') 0 0 / 100% 100% no-repeat;
    }
    .handBg1 div{
        color: #000000;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
    }
    .handBg div:nth-child(2){
        margin-left: -1px;
    }
    .handBg div:nth-child(3){
        margin-left: -1px;
    }
    .handBg div:nth-child(4){
        margin-left: -1px;
    }
     .handBg1 div:nth-child(2){
        margin-left: -1px;
    }
    .handBg1 div:nth-child(3){
        margin-left: -1px;
    }
    .handBg1 div:nth-child(4){
        margin-left: -1px;
    }
    .pinLIST div{
       
        font-family: "pinyin";;
        color: #000000;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
        background: url('https://r.bjwxjs.com/PC_kpy/IMG_9180.png') center center / 100% 60% no-repeat;
    }
    .pinLIST div:nth-child(2){
        margin-left: -1px;
    }
    .pinLIST div:nth-child(3){
        margin-left: -1px;
    }
    .pinLIST div:nth-child(4){
        margin-left: -1px;
    }
  </style>
  